import Image from 'next/image';
import * as React from 'react';
import { MutableRefObject, useRef } from 'react';

type IframeProps = React.IframeHTMLAttributes<HTMLIFrameElement> & {
    showShadow?: boolean;
    fullRounded?: boolean;
    title: string;
    priority?: boolean;
};

const Index = React.forwardRef<HTMLIFrameElement, IframeProps>(
    ({ fullRounded = true, showShadow = false, priority = false, ...props }, ref) => {
        const { src, title } = props;
        const myRef = useRef<HTMLIFrameElement>(null);
        const srcDoc = React.useMemo(() => {
            const base =
                '<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}a:hover .ytp-large-play-button-bg, a:focus .ytp-large-play-button-bg{color: #f00}</style><a style="display:block;height:100%;" href=https://www.youtube.com/embed/REPLACE_URL?autoplay=1></a>';
            return base.replace(/REPLACE_URL/gi, src).replace(/REPLACE_TITLE/gi, title);
        }, []);

        return (
            <button
                className={'relative h-0 text-gray-dark hover:text-red focus:text-red w-full'}
                style={{ paddingBottom: '56.25%' }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                        myRef.current?.contentDocument?.querySelector('a').click();
                    }
                }}>
                <svg
                    className={'absolute w-full top-0 bottom-0 h-12 m-auto'}
                    style={{ zIndex: 1 }}
                    height="100%"
                    version="1.1"
                    viewBox="0 0 68 48"
                    width="100%">
                    <path
                        className="ytp-large-play-button-bg"
                        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                        fill="currentColor"
                    />
                    <path d="M 45,24 27,14 27,34" fill="#fff" />
                </svg>
                <Image
                    className={`image rounded-xl ${!fullRounded && 'rounded-b-none'}`}
                    src={`/images/maxresdefault/${src}.jpg`}
                    layout={'responsive'}
                    width={800}
                    height={450}
                    title={title}
                    alt={title}
                    priority={priority}
                    tabIndex={0}
                />
                <iframe
                    title={title}
                    {...props}
                    srcDoc={srcDoc}
                    style={{ zIndex: 2 }}
                    className={`absolute top-0 left-0 w-full h-full rounded-xl ${
                        showShadow && 'shadow-xl'
                    } ${!fullRounded && 'rounded-b-none'}`}
                    ref={(node) => {
                        myRef.current = node;
                        if (typeof ref === 'function') {
                            ref(node);
                        } else if (ref) {
                            (ref as MutableRefObject<HTMLIFrameElement>).current = node;
                        }
                    }}
                    tabIndex={-1}
                />
            </button>
        );
    }
);

Index.displayName = 'Iframe';

export default Index;
